import styled from 'styled-components';

import { media, font } from '../../constants/theme';

export const StyledCarousel = styled.div`
  h2 {
    margin: 1rem 0 3rem;

    @media ${media.notMobile} {
      font-size: 38px;
      max-width: 700px;
    }
    @media ${media.mobile} {
      font-size: 28px;
    }
  }
  span {
    display: block;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: ${font.weight.bold};
  }
`;

export const StyledCarouselControls = styled.div`
  display: flex;
  margin: 1.5rem 0 2.5rem;
  align-items: center;

  @media ${media.mobile} {
    display: none;
  }

  ul {
    display: flex;
    font-size: 24px;
    margin: 0 1.5rem;

    li {
      margin: 0 0.5rem;
    }
  }
  svg {
    width: 2.25rem;
    height: 2.25rem;
    fill: currentColor;
  }
`;

export const StyledSlider = styled.div`
  ${({ currentPage }) => `
    display: flex;
    white-space: nowrap;
    transition: transform 0.5s ease-in-out;
    transform: translateX(-${(parseInt(currentPage) - 1) * 100}%);`};

  @media ${media.mobile} {
    overflow-x: scroll;
    margin: 0 -24px;
  }

  > div {
    padding: 0 3%;
    display: inline-block;
    white-space: normal;
    flex-shrink: 0;
    ${({ slidesPerPage }) => `
     width:${100 / slidesPerPage}%;`}

    @media ${media.mobile} {
      padding: 0 24px;
      width: 80vw;
    }
  }
`;
