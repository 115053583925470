import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Button } from '../../components';
import { StyledContactCard } from './styles';

export const ContactCard = ({ contact, ...props }) => {
  return (
    <StyledContactCard {...props}>
      <h2>Aristo Office</h2>
      <address>
        {contact.address}
        <br />
        {contact.city}, {contact.province} {contact.postalCode}
      </address>
      <Button
        variant="link"
        showArrow
        gutter={{ bottom: '2rem' }}
        href="https://goo.gl/maps/TF4TTE4ttpidfxsR9"
        target="_blank"
        rel="nofollow noreferrer noopener">
        Get google map directions
      </Button>
      <h2>Reach us directly</h2>
      <div>
        <Button
          variant="link"
          showArrow
          href={`tel:${contact.phone}`}>
          Call {contact.phone}
        </Button>
      </div>
      <div>
      <Button
          variant="link"
          showArrow
          href={`mailto:${contact.email}`}>
          {contact.email}
        </Button>
      </div>
    </StyledContactCard>
  );
};

ContactCard.propTypes = {
  contact: PropTypes.object.isRequired,
};
