import styled from 'styled-components';

import { colors, media } from '../../constants/theme';

export const StyledServiceAddonCard = styled.div`
  margin-bottom: 6rem;
  color: ${({ reversed }) => (reversed ? '#fff' : colors.text)};

  @media ${media.mobile} {
    margin-bottom: 4rem;
  }

  h3 {
    font-size: 44px;
    margin-bottom: 2rem;

    @media ${media.mobile} {
      font-size: 28px;
    }
  }
  p {
    font-size: 18px;
  }
`;
