import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';

// Components
import { Button, Disclaimer } from '../../components';
import { StyledContactForm, StyledInput } from './styles';

export const ContactForm = ({ contact, ...props }) => {
  const { register, errors, handleSubmit } = useForm();
  const [captcha, setCaptcha] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [progress, setProgress] = useState(false);

  const submit = (data) => {
    if (captcha.length > 1) {
      setProgress(true);
      fetch('https://epikode-api.uw.r.appspot.com/api/aristo/email', {
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
        method: 'POST',
        body: JSON.stringify(data),
      }).then((response) => {
        setProgress(false);
        if (response.status === 200) {
          // Success
          setSubmitted(true);
          setApiError(false);
        } else {
          // Fail
          setSubmitted(false);
          setApiError(true);
        }
      });
    }
  };

  // Wake API
  useEffect(() => {
    fetch('https://epikode-api.uw.r.appspot.com/');
  }, []);

  return (
    <StyledContactForm {...props}>
      {!submitted && (
        <form onSubmit={handleSubmit(submit)}>
          <h2>Send us an inquiry</h2>
          <p>We will get back to as soon as possible.</p>
          <p>All fields are required.</p>
          <StyledInput
            type="text"
            name="name"
            error={errors.name}
            ref={register({ required: true })}
            placeholder="Your full name"
          />
          {errors.name && <span className="error">Your name is required.</span>}
          <StyledInput
            type="email"
            name="email"
            error={errors.email}
            ref={register({
              required: true,
              pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
            placeholder="Email Address"
          />
          {errors.email && (
            <span className="error">A valid email address is required.</span>
          )}
          <StyledInput
            type="tel"
            name="phone"
            error={errors.phone}
            ref={register({ required: true })}
            placeholder="Phone number"
          />
          {errors.phone && (
            <span className="error">Phone number is required.</span>
          )}
          <StyledInput
            as="textarea"
            rows="8"
            name="message"
            error={errors.message}
            ref={register({ required: true })}
            placeholder="Your message"
          />
          {errors.message && (
            <span className="error">Message is required.</span>
          )}

          <p className="important">
            Please do not include confidential information in this message.
          </p>

          <ReCAPTCHA
            sitekey="6LfCsf0ZAAAAAPnbzct3G4iw-hXP7bSH1cf_r3Ue"
            onChange={(value) => setCaptcha(value)}
          />

          <Button type="submit" gutter={{ top: '1rem' }} disabled={progress}>
            {progress ? 'Sending...' : 'Send Message'}
          </Button>

          {Object.keys(errors).length > 0 && (
            <span className="error">
              Please fix the error(s) in your submission.
            </span>
          )}

          {apiError && (
            <span className="error">
              Something went wrong. Please try again.
            </span>
          )}

          <Disclaimer>
            Aristo values and protects your privacy. We do not give your
            personal information, without your consent, to any organization
            outside of our affiliates. Providing your phone number and email
            address enables Aristo to follow-up with you in regards to this
            request, we will not use your phone number or email for any other
            purpose.
          </Disclaimer>
        </form>
      )}

      {submitted && (
        <div>
          <h2>Thanks for the message!</h2>
          <p>
            One of our representatives will reach out to you as soon as
            possible. For immediate support, call us at {contact.phone}.
          </p>
        </div>
      )}
    </StyledContactForm>
  );
};

ContactForm.propTypes = {
  contact: PropTypes.object.isRequired,
};
