import React from 'react';
import PropTypes from 'prop-types';

import { StyledServiceGroup, StyledRow } from './styles';

export const ServiceGroup = ({
  children,
  title,
  subtitle = 'Services & Products',
  rows = 3,
  scrollOnMobile,
  ...props
}) => {
  return (
    <StyledServiceGroup {...props}>
      {title && (
        <header>
          <span>{subtitle}</span>
          <h2>{title}</h2>
        </header>
      )}
      <StyledRow scrollOnMobile={scrollOnMobile} rows={rows}>{children}</StyledRow>
    </StyledServiceGroup>
  );
};

ServiceGroup.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.any,
  scrollOnMobile: PropTypes.bool,
  rows: PropTypes.oneOf([3, 2]),
};
