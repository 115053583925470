import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Button } from '../../components';
import { StyledServiceAddonCard } from './styles';

export const ServiceAddonCard = ({
  links,
  title,
  text,
  reversed,
  cta: { src, label } = {},
  ...props
}) => {
  return (
    <StyledServiceAddonCard reversed={reversed} {...props}>
      {title && <h3>{title}</h3>}
      {text && <p>{text}</p>}
      {links && (
        <ul>
          {links.map((link, index) => (
            <li key={index}>
              <Button
                reversed={reversed}
                variant="link"
                showArrow
                to={link.src}>
                {link.label}
              </Button>
            </li>
          ))}
        </ul>
      )}
      {src && label && (
        <Button
          to={src}
          variant="outline"
          reversed={reversed}
          gutter={{ top: '2rem' }}>
          {label}
        </Button>
      )}
    </StyledServiceAddonCard>
  );
};

ServiceAddonCard.propTypes = {
  reversed: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
  links: PropTypes.array,
  cta: PropTypes.shape({
    src: PropTypes.string,
    label: PropTypes.string,
  }),
};
