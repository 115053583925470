import styled, { css } from 'styled-components';
import { media, colors, font, header } from '../../constants/theme';
import { Statement } from '../statement';

export const StyledHero = styled.header`
  position: relative;

  @media ${media.notMobile} {
    &:after {
      content: '';
      position: absolute;
      top: ${`-${header.fullHeight}`};
      left: 0;
      right: 0;
      width: 100%;
      background-color: ${colors.brand};
      height: ${header.fullHeight};
    }
  }

  @media ${media.notMobile} {
    height: 600px;
  }
  @media ${media.mobile} {
    height: auto;

    h2 {
      font-size: 18px;
      margin-bottom: 1.5rem;
    }

    > div {
      padding: 0;
    }
  }

  @media ${media.notMobile} {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 400px;
      background-color: ${colors.brand};
      z-index: -2;
    }
  }
`;

export const StyleHeaderWrapper = styled.div`
  color: #fff;
  background-color: ${colors.brandDark};
  overflow: hidden;
  z-index: -1;

  @media ${media.mobile} {
    padding: calc(7rem + ${header.condensedHeight}) 24px 7rem;
    margin-bottom: 3rem;
  }

  @media ${media.notMobile} {
    padding: 6rem 4.5rem 12rem;
    border-radius: 25px;
  }

  ${({ imagePath }) =>
    imagePath &&
    css`
      background-image: url('${imagePath}');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.55);
        z-index: -1;
      }
    `}

  > div {
    z-index: 5;
    @media ${media.notMobile} {
      min-height: 218px;
    }
  }

  span {
    display: block;
    font-weight: ${font.weight.bold};
    text-transform: uppercase;
  }

  @media ${media.notMobile} {
    h1 {
      font-size: 44px;
      max-width: 600px;
      z-index: 4;
    }
    span {
      font-size: 18px;
      margin-bottom: 1.5rem;
      z-index: 4;
    }
  }

  @media ${media.mobile} {
    h1 {
      font-size: 28px;
      line-height: 1.5;
    }
    span {
      font-size: 14px;
      margin-bottom: 1rem;
    }
  }
`;

export const StyledStatement = styled(Statement)`
  z-index: 9;

  @media ${media.desktop} {
    max-width: 70%;
    min-width: 840px;
    margin-top: -8rem;
  }
  @media ${media.tablet} {
    max-width: 90%;
    margin-top: -10rem;
  }
  @media ${media.mobile} {
    display: none;
  }
`;

export const StyledSecondaryTitleImage = styled.div`
  width: 120px;
  margin: 1rem auto;
`;
