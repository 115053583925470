import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Image } from '../../components';
import { StyledPartners } from './styles';

export const Partners = ({ title, text, companies, ...props }) => {
  return (
    <StyledPartners {...props}>
      <div>
        {title && <h2>{title}</h2>}
        {text && <p>{text}</p>}
      </div>
      <div>
        {companies.map((company, index) => {
          const { name, href, imagePath } = company;
          return (
            <a
              key={index}
              href={href}
              target="_blank"
              rel="nofollow noreferrer noopener">
              <Image src={imagePath} alt={name} />
            </a>
          );
        })}
      </div>
    </StyledPartners>
  );
};

Partners.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  companies: PropTypes.array,
};
