import React from 'react';

// Components
import { Image } from '../../components';
import { StyledHomeHeaderBg } from './styles';

export const HomeHeaderBg = () => (
  <StyledHomeHeaderBg>
    <Image src="home/aristo-hp.jpg" />
  </StyledHomeHeaderBg>
);
