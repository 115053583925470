import styled from 'styled-components';
import { colors, font } from '../../constants/theme';

export const StyledContactForm = styled.div`
  margin-bottom: 6rem;

  h2 {
    font-size: 24px;
    margin-bottom: 1rem;
  }

  .error {
    display: block;
    font-size: 14px;
    color: #d00000;
    margin-top: 1rem;
  }
  input + .error,
  textarea + .error {
    margin-top: -0.5rem;
  }

  .important {
    font-weight: ${font.weight.bold};
    margin: 1rem 0;
  }
`;

export const StyledInput = styled.input`
  /* Resets */
  margin: 0;
  border: 0;
  padding: 0;
  vertical-align: middle;
  white-space: normal;
  background: none;
  line-height: 1;
  font-size: 16px;
  font-family: ${font.family.default};

  /* Custom styles */
  display: block;
  border: 2px solid ${({ error }) => (error ? '#d00000' : colors.brand)};
  padding: 0.75rem 1.5rem;
  width: 100%;
  margin: 1rem 0;
  border-radius: 6px;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #8cc9e6;
  }
`;
