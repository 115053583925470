import styled, { css } from 'styled-components';
import { font, media } from '../../constants/theme';

export const StyledServiceGroup = styled.article`
  h2 {
    margin: 1rem 0 3rem;

    @media ${media.notMobile} {
      font-size: 38px;
    }
    @media ${media.mobile} {
      font-size: 28px;
    }
  }
  span {
    display: block;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: ${font.weight.bold};
  }
`;
export const StyledRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    ${({ rows }) =>
      rows === 3
        ? css`
            @media ${media.desktop} {
              width: 30%;
            }
            @media ${media.tablet} {
              width: 48%;
            }
          `
        : css`
            @media ${media.desktop} {
              width: 44%;
            }
            @media ${media.tablet} {
              width: 48%;
            }
          `}

    @media ${media.mobile} {
      width: 100%;
    }
  }

  @media ${media.mobile} {
    ${({ scrollOnMobile }) =>
      scrollOnMobile
        ? `
      display: block;
      overflow-x: scroll;
      flex-wrap: no-wrap;
      white-space: nowrap;
      margin: 0 -24px 2rem;

      > div {
        width: 80%;
        display: inline-block;
        white-space: normal;
        padding: 0 24px;
        vertical-align: top;
      }
    
    `
        : undefined}
  }
`;
