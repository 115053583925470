import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            image
            name
            address
            city
            province
            postalCode
            country
            phone
            latitude
            longitude
            email
            author
          }
        }
      }
    `,
  );

  const { title: defaultTitle, description: defaultDescription, image, siteUrl, name, address, city, province, postalCode, country, phone, email, author, latitude, longitude  } = site.siteMetadata;

  const metaDescription = description || defaultDescription;
  // const defaultTitle = site.siteMetadata?.title;
  // const image = site.siteMetadata?.image;
  // const url = site.siteMetadata?.url;
  
  const metaData = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      name: `image`,
      content: image,
    },
    {
      name: `viewport`,
      content: `width=device-width, initial-scale=1.0`,
    },
    {
      property: `og:url`,
      content: siteUrl,
    },
    {
      property: `og:site_name`,
      content: name,
    },
    {
      property: `og:title`,
      content: `${title} | ${defaultTitle}`,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:image`,
      content: image,
    },
    {
      name: `twitter:url`,
      content: siteUrl,
    },
    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
    {
      name: `twitter:creator`,
      content: author || ``,
    },
    {
      name: `twitter:title`,
      content: `${title} | ${defaultTitle}`,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    {
      name: `twitter:image`,
      content: image,
    },
  ];

  if (process.env.GATSBY_ACTIVE_ENV === 'dev') {
    metaData.push({
      name: "robots",
      content: "noindex",
    });
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={metaData.concat(meta)}>
      {/* Schema Data */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "${name}",
            "image": "${image}",
            "@id": "${siteUrl}",
            "url": "${siteUrl}",
            "telephone": "${phone}",
            "email": "${email}",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "${address}",
              "addressLocality": "${city}",
              "addressRegion": "${province}",
              "postalCode": "${postalCode}",
              "addressCountry": "${country}"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": "${latitude}",
              "longitude": "${longitude}"
            }  
          }
        `}
      </script>
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
