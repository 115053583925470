import styled from 'styled-components';
import { media } from '../../constants/theme';

export const StyledHomeFooterBg = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -1;

  @media ${media.mobile} {
    left: -15%;
    width: 200%;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    width: 100vw;
  }
`;
