import React from 'react';
import PropTypes from 'prop-types';

// Const & Utils
import { useWindowSize } from '../../utils/useWindowSize';
import { mediaSize } from '../../constants/theme';

// Components
import { Container, Image } from '../../components';
import {
  StyledHero,
  StyleHeaderWrapper,
  StyledStatement,
  StyledSecondaryTitleImage,
} from './styles';

export const Hero = ({
  imagePath,
  title,
  subtitle,
  secondaryTitle,
  secondaryTitleImgSrc,
  text,
  children,
  ...props
}) => {
  const isMobile = useWindowSize().width <= mediaSize.mobile;
  return (
    <StyledHero {...props}>
      <Container as="div">
        <StyleHeaderWrapper {...{ imagePath }}>
          <div>
            {subtitle && <span>{subtitle}</span>}
            {title && <h1>{title}</h1>}
          </div>
        </StyleHeaderWrapper>

        {isMobile ? (
          <>
            {/* Mobile Layout */}
            <Container as="div" gutter={{ bottom: '3rem' }}>
              <div style={{ marginTop: '4rem', marginBottom: '4rem' }}>
                {(secondaryTitle || secondaryTitleImgSrc) && (
                  <>
                    {secondaryTitleImgSrc ? (
                      <StyledSecondaryTitleImage>
                        <Image src={secondaryTitleImgSrc} />
                      </StyledSecondaryTitleImage>
                    ) : (
                      <h2>{secondaryTitle}</h2>
                    )}
                  </>
                )}
                {text && <p>{text}</p>}
                {Object.keys(children).length > 0 && children}
              </div>
            </Container>
          </>
        ) : (
          <>
            {/* Desktop Layout */}
            {(text || Object.keys(children).length > 0) && (
              <StyledStatement
                title={secondaryTitle}
                titleImgSrc={secondaryTitleImgSrc}
                condensed>
                <p>{text}</p>
                {Object.keys(children).length > 0 && children}
              </StyledStatement>
            )}
          </>
        )}
      </Container>
    </StyledHero>
  );
};

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  secondaryTitle: PropTypes.string,
  secondaryTitleImgSrc: PropTypes.string,
  text: PropTypes.string,
  imagePath: PropTypes.string,
};
