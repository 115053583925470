import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Button, Image } from '../../components';

import { StyledEndHero, StyledContainer, StyledBackgroundImg } from './styles';

export const EndHero = ({
  imagePath,
  title,
  text,
  cta: { src, label } = {},
  ...props
}) => {
  return (
    <StyledEndHero {...props}>
      {imagePath && (
        <StyledBackgroundImg>
          <Image src={imagePath} alt={title} />
        </StyledBackgroundImg>
      )}
      <StyledContainer>
        {title && <h2>{title}</h2>}
        {text && <p>{text}</p>}
        {src && label && (
          <Button to={src} variant="outline" reversed>
            {label}
          </Button>
        )}
      </StyledContainer>
    </StyledEndHero>
  );
};

EndHero.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  imagePath: PropTypes.string,
  cta: PropTypes.shape({
    src: PropTypes.string,
    label: PropTypes.string,
  }),
};
