import React from 'react';
import PropTypes from 'prop-types';

// Components
import { StyledTestimonial } from './styles';
import { QuoteIcon, UserIcon } from '../../components';

export const Testimonial = ({ text, author, reversed, ...props }) => {
  return (
    <StyledTestimonial reversed={reversed} {...props}>
      <QuoteIcon className="quote-icon" />
      <p>{text}</p>
      <address rel="author">
        <UserIcon className="user-icon" />
        <span translate="no">{author}</span>
      </address>
    </StyledTestimonial>
  );
};

Testimonial.propTypes = {
  text: PropTypes.string,
  author: PropTypes.string,
  reversed: PropTypes.bool,
};
