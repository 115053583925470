import React from 'react';
import PropTypes from 'prop-types';

import { Button, Image } from '../../components';
import {
  StyledStatement,
  StyledTitle,
  StyledBody,
  StyledBackgroundImg,
} from './styles';

export const Statement = ({
  children,
  title,
  titleImgSrc,
  condensed = false,
  removeMobileStyles = false,
  maxWidth = 'none',
  gutter = {},
  styleType = 'default',
  cta: { src, label } = {},
  bgImgSrc,
  ...props
}) => {
  return (
    <StyledStatement
      {...{
        condensed,
        removeMobileStyles,
        maxWidth,
        gutter,
        styleType,
        bgImgSrc,
      }}
      {...props}>
      {bgImgSrc && (
        <StyledBackgroundImg>
          <Image src={bgImgSrc} alt={title} />
        </StyledBackgroundImg>
      )}
      {(title || titleImgSrc) && (
        <StyledTitle as={titleImgSrc && 'div'} {...{ condensed }}>
          {titleImgSrc ? <Image src={titleImgSrc} alt={title} /> : title}
        </StyledTitle>
      )}
      <StyledBody {...{ condensed }}>
        {children}
        {src && label && (
          <Button
            to={src}
            variant="outline"
            reversed={styleType === 'reversed'}
            gutter={{ top: '1.5rem' }}>
            {label}
          </Button>
        )}
      </StyledBody>
    </StyledStatement>
  );
};

Statement.propTypes = {
  title: PropTypes.string,
  titleImgSrc: PropTypes.string,
  children: PropTypes.any,
  maxWidth: PropTypes.string,
  condensed: PropTypes.bool,
  gutter: PropTypes.object,
  styleType: PropTypes.oneOf(['default', 'dark', 'cta']),
  cta: PropTypes.shape({
    src: PropTypes.string,
    label: PropTypes.string,
  }),
  removeMobileStyles: PropTypes.bool,
  bgImgSrc: PropTypes.string,
};
