import styled from 'styled-components';
import { Container } from '../container';
import { colors, media, font, header } from '../../constants/theme';

export const StyledHeader = styled.div`
  transition: all 650ms ease-in;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 900;

  @media ${media.condensedNav} {
    top: ${({ allowSticky, sticky }) => {
      if (allowSticky && sticky) return '0';
      if (allowSticky && !sticky) return header.negativeCondensedHeight;
    }};
  }
  @media ${media.fullNav} {
    top: ${({ allowSticky, sticky }) => {
      if (allowSticky && sticky) return '0';
      if (allowSticky && !sticky) return header.negativeFullHeight;
    }};
  }

  ${({ darkTheme, offsetMobileHeader }) =>
    darkTheme
      ? `
      color: #fff;
      @media ${media.notMobile} {
        background-color: ${colors.brand};
      }
      @media ${media.mobile} {
        background-color: ${offsetMobileHeader ? colors.darkBg : 'transparent'};
      }
    `
      : `
      color: ${colors.brand};
      background-color: ${offsetMobileHeader ? '#fff' : 'transparent'};

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        background-color: #fff;
      }
  `}

  ${({ sticky, allowSticky }) =>
    sticky && allowSticky
      ? `
    position: fixed;
    background-color: rgba(0,0,0,0.9) !important;
  `
      : undefined}

  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
`;

export const StyledContainer = styled(Container)`
  display: flex;
  align-content: center;
  align-items: center;

  @media ${media.condensedNav} {
    height: ${header.condensedHeight};
  }
  @media ${media.fullNav} {
    height: ${header.fullHeight};
  }
`;

export const StyledLogo = styled.div`
  @media ${media.condensedNav} {
    height: 45px;
  }
  @media ${media.fullNav} {
    height: 60px;
  }

  svg {
    height: 100%;
  }
`;

export const StyledNav = styled.nav`
  margin-left: auto;

  @media ${media.notDesktop} {
    display: none;
  }

  ul {
    display: flex;
  }

  li {
    padding: 0 1.2rem;
  }

  a {
    display: block;
    font-weight: ${font.weight.navLinks};
    padding: 0.8rem 0;
    position: relative;

    &,
    &:hover {
      color: ${({ darkTheme }) => (darkTheme ? '#fff' : colors.brand)};
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      bottom: 0;
      height: 3px;
      width: 0;
      background: ${({ darkTheme }) => (darkTheme ? '#fff' : colors.brand)};
      transition: width 200ms ease-in 0s, left 200ms ease-in 0s;
    }

    &:hover :after {
      width: 100%;
      left: 0;
    }
  }
`;

export const StyledMobileToggle = styled.button`
  /* Resets */
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  cursor: pointer;
  font: inherit;
  line-height: normal;
  user-select: none;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;

  /* Styles */
  margin-left: auto;
  z-index: 1100;
  width: 2rem;
  height: 2rem;
  ${({ menuOpen }) =>
    menuOpen
      ? `
      position: fixed;
      right : 24px;
      `
      : `
      position: relative;
  `};

  &:focus {
    outline: none;
  }

  span {
    position: absolute;
    width: 2rem;
    height: 2px;
    border-radius: 2px;
    background-color: ${({ darkTheme }) => (darkTheme ? '#fff' : colors.brand)};
    transition: 250ms ease-in;
    opacity: 1;
    left: 0;

    &:nth-child(1) {
      top: calc(1rem - 0.3rem);
    }
    &:nth-child(2) {
      top: calc(1rem + 0.3rem);
    }

    ${({ menuOpen }) =>
      menuOpen &&
      `
      background-color: #fff;

			&:nth-child(1) {
        transform: rotate(-45deg);
        top: 1rem;
			}
			&:nth-child(2) {
        transform: rotate(45deg);
        top: 1rem;
			}
    `};
  }
`;

export const StyledMobileNav = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: ${colors.brand};
  color: #fff;
  position: fixed;
  transition: top 450ms ease-in;
  transition-delay: 200ms;
  top: ${({ menuOpen }) => (menuOpen ? '0' : '-100%')};
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;

  svg {
    height: 9rem;
    padding: 0 2.3rem;
    margin-bottom: 1.5rem;
    filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.25));

    @media ${media.tablet} {
      margin: 0 auto;
    }
  }

  ul {
    width: 100%;
  }

  li {
    overflow: hidden;
    height: 55px;
    position: relative;
  }

  a {
    font-size: 20px;
    display: block;
    color: #fff;
    padding: 1rem 2.5rem;
    text-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
    transition: top 350ms ease-in;
    transition-delay: 650ms;
    position: absolute;
    left: 0;
    top: ${({ menuOpen }) => (menuOpen ? '0' : '-100%')};

    @media ${media.tablet} {
      text-align: center;
    }
  }
`;

export const StyledBgWrapper = styled.div`
  z-index: -1;
  position: absolute;
  overflow: hidden;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0%;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  > div {
    width: 130%;
    top: -7rem;
    left: -15%;
  }
`;
