import styled from 'styled-components';

import { media, colors } from '../../constants/theme';

export const StyledInfo = styled.div`

  /* Gutter */
  margin-top: ${({ gutter }) => gutter.top || '4rem'};
  margin-bottom: ${({ gutter }) => gutter.bottom || '4rem'};

  @media ${media.notMobile} {
    display: flex;
    align-items: flex-start;
  }

  > div:first-child {
    border-radius: 25px;
    overflow: hidden;

    @media ${media.notMobile} {
      width: 40%;
      flex-shrink: 0;
    }

    @media ${media.mobile} {
      margin: 0 auto;
      margin-bottom: 2rem;
    }

    > div {
      width: 100%;
      height: 100%;
    }
  }

  > div:last-child {
    @media ${media.notMobile} {
      width: 50%;
      flex-shrink: 0;
      margin-left: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    h3 {
      font-size: 44px;
      margin-bottom: 2rem;

      @media ${media.mobile} {
        font-size: 30px;
      }
    }

    p {
      font-size: 18px;

      @media ${media.mobile} {
        font-size: 16px;
      }
    }

    ul {
      margin: 1.5rem 0;

      li {
        margin-bottom: 1rem;
      }

      span {
        font-size: 14px;
        color: ${colors.mute};
      }
    }
  }
`;
