import styled from 'styled-components';
import { media, colors, font } from '../../constants/theme';

export const StyledHero = styled.header`

  @media ${media.notMobile} {
    color: ${colors.brand};
    margin-right: 4rem;
    padding: 4rem 0;
    max-width: 600px;
    margin-left: auto;

    h1 {
      font-size: 56px;
    }
    p {
      font-weight: ${font.weight.bold};
      font-size: 18px;
      margin: 1.5rem 0;
    }
  }

  @media ${media.tablet} {
    padding: 8rem 0 4rem;
  }

  @media ${media.mobile} {
    color: #fff;
    padding: 30vh 0 4rem;

    h1 {
      font-size: 36px;
      text-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
    }
    p {
      font-size: 16px;
      margin: 1.5rem 0;
      text-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
    }
  }
`;
