import React from 'react';
import PropTypes from 'prop-types';

// Const & Utils
import { useWindowSize } from '../../utils/useWindowSize';
import { mediaSize } from '../../constants/theme';

// Components
import { Button, Container } from '../../components';
import { StyledHero } from './styles';

export const HomeHero = ({
  title,
  text,
  cta: { src, label } = {},
  ...props
}) => {
  const isMobile = useWindowSize().width <= mediaSize.mobile;
  return (
    <Container {...props}>
      <StyledHero>
        {title && <h1>{title}</h1>}
        {text && <p>{text}</p>}
        {src && label && (
          <Button
            to={src}
            variant={isMobile ? 'outline' : 'solid'}
            reversed={isMobile}>
            {label}
          </Button>
        )}
      </StyledHero>
    </Container>
  );
};

HomeHero.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  cta: PropTypes.shape({
    src: PropTypes.string,
    label: PropTypes.string,
  }),
};
