import styled from 'styled-components';

import { colors, media } from '../../constants/theme';

export const StyledServiceCard = styled.div`
  margin-bottom: 6rem;

  @media ${media.mobile} {
    margin-bottom: 4rem;
  }

  &,
  a:not(:hover) {
    color: ${({ reversed }) => (reversed ? '#fff' : colors.text)};
  }

  h3 {
    font-size: 18px;
    margin-bottom: 1rem;
  }
`;
export const StyledImageWrapper = styled.div`
  border-radius: 25px;
  overflow: hidden;
  position: relative;
  margin-bottom: 2rem;

  &:hover {
    > div {
      transform: scale(1.1);
    }
  }
`;

export const StyledAspectRatio = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 66.66%;
  transition: transform 250ms ease-in;
`;
