import styled from 'styled-components';
import { media, header } from '../../constants/theme';

export const StyledHomeHeaderBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;

  @media ${media.desktop} {
    top: ${`-${header.fullHeight}`};
  }
  @media ${media.mobile} {
    left: -20%;
    width: 120%;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    width: 100vw;
  }
`;
