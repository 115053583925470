import React from 'react';

// Components
import { Image } from '../../components';
import { StyledHomeFooterBg } from './styles';

export const HomeFooterBg = () => (
  <StyledHomeFooterBg>
    <Image src="home/aristo-footer.jpg" />
  </StyledHomeFooterBg>
);
