import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Button, Image, Link } from '../../components';
import {
  StyledServiceCard,
  StyledImageWrapper,
  StyledAspectRatio,
} from './styles';

export const ServiceCard = ({
  imagePath,
  title,
  text,
  reversed,
  buttonProps = { variant: 'outline', showArrow: true },
  cta: { src, label } = {},
  ...props
}) => {
  return (
    <StyledServiceCard reversed={reversed} {...props}>
      {imagePath && (
        <Link to={src}>
          <StyledImageWrapper>
            <StyledAspectRatio>
              <Image src={imagePath} alt={title}/>
            </StyledAspectRatio>
          </StyledImageWrapper>
        </Link>
      )}
      {title && (
        <h3>
          <Link to={src}>{title}</Link>
        </h3>
      )}
      {text && <p>{text}</p>}
      {src && label && (
        <Button
          {...buttonProps}
          to={src}
          reversed={reversed}
          aria-label={`Learn more about ${title}`}
          gutter={{ top: '1rem' }}>
          {label}
        </Button>
      )}
    </StyledServiceCard>
  );
};

ServiceCard.propTypes = {
  reversed: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
  imagePath: PropTypes.string,
  buttonProps: PropTypes.object,
  cta: PropTypes.shape({
    src: PropTypes.string,
    label: PropTypes.string,
  }),
};
