import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Image, Button } from '../../components';
import { StyledHomeContent, StyledImageList, StyledBlocks } from './styles';

export const HomeContent = ({ reversed, imageList, blocks, ...props }) => {
  return (
    <StyledHomeContent reversed={reversed} {...props}>
      {imageList && (
        <StyledImageList>
          {imageList.map((imageSrc, index) => {
            return (
              <div key={index}>
                <Image src={imageSrc} alt="aristo wealth management" />
              </div>
            );
          })}
        </StyledImageList>
      )}
      {blocks && (
        <StyledBlocks>
          {blocks.map((block, index) => {
            const { title, text, cta = {} } = block;
            return (
              <div key={index}>
                {title && <h3>{title}</h3>}
                {text && <p>{text}</p>}
                {cta.src && cta.label && (
                  <Button
                    to={cta.src}
                    variant="outline"
                    reversed={reversed}
                    gutter={{ top: '2rem' }}>
                    {cta.label}
                  </Button>
                )}
              </div>
            );
          })}
        </StyledBlocks>
      )}
    </StyledHomeContent>
  );
};

HomeContent.propTypes = {
  reversed: PropTypes.bool,
  imageList: PropTypes.array,
  blocks: PropTypes.array,
};
