import React from 'react';
import PropTypes from 'prop-types';

// Components
import {
  ServiceCard,
  Container,
  Statement,
  ServiceGroup,
  Disclaimer,
} from '../../components';

// Data
import services from '../../data/services.json';

export const ServicePage = ({ pageContext = {} }) => {
  return (
    <>
      <Container offsetHero>
        {/* Iterate through service groups */}
        <ServiceGroup rows={2}>
          {/* Iterate and map ServiceCard */}
          {pageContext.content.products &&
            pageContext.content.products.map((product, index) => (
              <ServiceCard
                key={index}
                title={product.title}
                text={product.description}
                buttonProps={{ variant: 'link', showArrow: true }}
                cta={{
                  src: '/contact',
                  label: `Schedule a meeting for ${product.title.toLowerCase()}`,
                }}
              />
            ))}
        </ServiceGroup>

        <div className="service-slider"></div>

        {/* CTA Statement*/}
        <Statement
          title={pageContext.content.statement.title}
          cta={pageContext.content.statement.cta}
          styleType="cta">
          <p>{pageContext.content.statement.text}</p>
        </Statement>

        {pageContext.content.disclaimer && (
          <Disclaimer>Disclaimer: {pageContext.content.disclaimer}</Disclaimer>
        )}
      </Container>

      {/* Related Products */}
      <Container gutter={{ top: '10rem' }}>
        <ServiceGroup title="Related Services & Products" scrollOnMobile>
          {pageContext.related &&
            pageContext.related.map(
              (product, index) =>
                services.data[product] && (
                  <ServiceCard
                    key={index}
                    imagePath={services.data[product].imagePath}
                    title={services.data[product].title}
                    text={services.data[product].blurb}
                    cta={{
                      src: services.data[product].path,
                      label: 'Learn More',
                    }}
                  />
                ),
            )}
        </ServiceGroup>
      </Container>
    </>
  );
};

ServicePage.propTypes = {
  pageContext: PropTypes.object.isRequired,
};
