import styled from 'styled-components';
import { Container } from '../container';
import { media, font } from '../../constants/theme';

export const StyledEndHero = styled.div`
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;

  @media ${media.notMobile} {
    margin-top: 8rem;
    min-height: 80vh;
  }
  @media ${media.mobile} {
    margin-top: 4rem;
  }
`;

export const StyledBackgroundImg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  > div {
    height: 100%;
    width: 100%;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.55);
  }
`;

export const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${media.notMobile} {
    margin: 0 auto;
    padding-top: 8rem;
    padding-bottom: 8rem;

    h2 {
      font-size: 44px;
      max-width: 880px;
    }
    p {
      font-weight: ${font.weight.bold};
      font-size: 18px;
      margin: 1.5rem 0;
      max-width: 700px;
    }
  }

  @media ${media.mobile} {
    padding-top: 7rem;
    padding-bottom: 7rem;

    h2 {
      font-size: 28px;
      line-height: 1.5;
    }
    p {
      font-size: 16px;
      margin: 1.5rem 0;
    }
  }
`;
