import styled from 'styled-components';

import { media, colors, font } from '../../constants/theme';

export const StyledTestimonial = styled.article`
  background-color: ${({ reversed }) =>
    reversed ? 'rgba(0,0,0,0.35)' : colors.brandLight};
  color: ${({ reversed }) => (reversed ? '#fff' : colors.text)};
  border-radius: 25px;
  position: relative;
  margin-top: 1rem;

  @media ${media.notMobile} {
    padding: 4rem 3rem 3rem;
  }

  @media ${media.mobile} {
    padding: 3rem 2rem 2rem;
  }

  p {
    font-size: 14px;
  }

  address {
    text-align: right;
    display: block;
    margin-top: 1.5rem;
    font-size: 16px;
    font-weight: ${font.weight.bold};
    display: flex;
    align-items: center;
  }

  svg {
    fill: currentColor;
  }

  .quote-icon {
    position: absolute;
    opacity: 0.5;

    @media ${media.notMobile} {
      width: 4rem;
      left: -0.5rem;
      top: -1rem;
    }

    @media ${media.mobile} {
      width: 2.5rem;
      left: 0;
      top: -1rem;
    }
  }

  .user-icon {
    width: 1rem;
    margin-right: 0.7rem;
    margin-left: auto;
  }
`;
