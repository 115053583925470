import React from "react"
import PropTypes from "prop-types"

// Components
import { Container, Link, LogoStackedLight } from '../../components';
import {
  StyledFooter,
  StyledFooterBody,
  StyledFooterCol,
  StyledLogo,
  StyledCopyright,
} from "./styles"

// Data
import data from "../../data/footer.json"

export const Footer = ({ siteTitle = "" }) => {
  const { description, nav_primary, nav_secondary } = data

  return (
    <StyledFooter>
      <StyledFooterBody>
        <StyledFooterCol>
          <StyledLogo>
            <LogoStackedLight />
          </StyledLogo>
          <p>{description}</p>
        </StyledFooterCol>
        <StyledFooterCol>
          <ul>
            {nav_secondary.map((link, index) => (
              <li key={index}>
                <Link to={link.src}>{link.label}</Link>
              </li>
            ))}
          </ul>
          <ul>
            {nav_primary.map((link, index) => (
              <li key={index}>
                <Link to={link.src}>{link.label}</Link>
              </li>
            ))}
          </ul>
        </StyledFooterCol>
      </StyledFooterBody>
      <StyledCopyright>
        <Container translate="no">
          {siteTitle} © {new Date().getFullYear()} All Rights Reserved. Website by <a href="https://www.epikode.com" target="_blank" rel="nofollow noreferrer noopener">Epikode</a>. V1.0.13.
        </Container>
      </StyledCopyright>
    </StyledFooter>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}
