import styled from 'styled-components';

import { media, colors } from '../../constants/theme';

export const StyledNewsCard = styled.div`
  /* Gutter */
  margin-top: ${({ gutter }) => gutter.top || '2rem'};
  margin-bottom: ${({ gutter }) => gutter.bottom || '8rem'};

  @media ${media.desktop} {
    display: flex;
    align-items: flex-start;
  }

  > div:first-child {
    border-radius: 25px;
    overflow: hidden;

    @media ${media.desktop} {
      width: 40%;
      flex-shrink: 0;
      height: 350px;
    }

    @media ${media.notDesktop} {
      height: 280px;
      margin: 0 auto;
      margin-bottom: 2rem;
    }

    @media ${media.tablet} {
      height: 400px;
    }

    > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  > div:last-child {
    @media ${media.desktop} {
      width: 50%;
      flex-shrink: 0;
      margin-left: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    h2 {
      font-size: 28px;
      margin-bottom: 2rem;

      @media ${media.notDesktop} {
        font-size: 22px;
      }
    }
  }
`;
