import styled from 'styled-components';
import { media } from '../../constants/theme';

export const StyledContactCard = styled.div`
  h2 { 
    font-size: 24px;
    margin-bottom: 1rem;
  }
  a {
    margin-left: -6px;
  }
  address {
    line-height: 1.5;
  }
`;
