import React from 'react';
import PropTypes from 'prop-types';

// Components
import { StyledDisclaimer } from './styles';

export const Disclaimer = ({ children, ...props }) => {
  return <StyledDisclaimer {...props}>{children}</StyledDisclaimer>;
};

Disclaimer.propTypes = {
  children: PropTypes.any,
};
