import styled from 'styled-components';

import { media, font } from '../../constants/theme';

export const StyledStaff = styled.div`
  margin-top: 6rem;
`;

export const StyledStaffBlock = styled.div`
  margin-bottom: 4rem;
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;

  @media ${media.notMobile} {
    display: flex;
  }

  > div:first-child {
    border-radius: 25px;
    overflow: hidden;

    @media ${media.notMobile} {
      width: 35%;
      flex-shrink: 0;
    }

    @media ${media.mobile} {
      margin: 0 auto;
      height: 350px;
      margin-bottom: 2rem;
    }

    > div {
      width: 100%;
      height: 100%;
    }

  }

  > div:last-child {
    @media ${media.notMobile} {
      width: 55%;
      flex-shrink: 0;
      margin-left: auto;
    }

    h4 {
      font-size: 26px;
    }
    h5 {
      margin: 1rem 0;
      text-transform: uppercase;
      font-weight: ${font.weight.normal};
    }
  }
`;
