import styled from 'styled-components';

export const StyledNewsPost = styled.div`
  font-size: 18px;

  p {
    margin-bottom: 2rem;
  }

  h2 {
    font-size: 22px;
    margin: 4rem 0 2rem;
  }

  ul {
    list-style: disc;
    margin: 1.25rem;
    line-height: 1.5;
  }

  figure {
    margin: 2rem 0;
  }

  img {
    max-width: 100%;
    display: block;
    margin: 0 auto;
  }
`;
