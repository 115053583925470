import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Image } from '..';
import { StyledInfo } from './styles';

export const Info = ({
  title,
  text,
  imagePath,
  children,
  gutter = {},
  ...props
}) => {
  return (
    <StyledInfo {...{ gutter }} {...props}>
      <div>
        <Image src={imagePath} alt={title} />
      </div>
      <div>
        {title && <h3>{title}</h3>}
        {text && <p>{text}</p>}
        {children}
      </div>
    </StyledInfo>
  );
};

Info.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  imagePath: PropTypes.string,
  gutter: PropTypes.object,
};
