import React from "react"
import PropTypes from "prop-types"

import { StyledContainer } from './styles'

export const Container = ({ maxWidth, children, fluid, gutter = {}, offsetHero, ...props }) => {
  return (
    <StyledContainer {...{ maxWidth, fluid, gutter, offsetHero }} {...props}>
      {children}
    </StyledContainer>
  )
}

Container.propTypes = {
  fluid: PropTypes.bool,
  offsetHero: PropTypes.bool,
  gutter: PropTypes.object,
  maxWidth: PropTypes.string,
}
