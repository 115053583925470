import styled from 'styled-components';
import { media, header } from '../../constants/theme';

export const StyledLayoutWrapper = styled.div`
  @media ${media.fullNav} {
    padding-top: ${header.fullHeight};
  }
  @media ${media.tablet} {
    padding-top: ${header.condensedHeight};
  }

  ${({ offsetMobileHeader }) =>
    offsetMobileHeader
      ? `
      @media ${media.condensedNav} {
        padding-top: ${header.condensedHeight};
      }
  `
      : undefined}
`;
