import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import { StyledCarousel, StyledCarouselControls, StyledSlider } from './styles';

import { Button, LeftArrowIcon, RightArrowIcon } from '..';

const CarouselControls = ({
  reversed,
  currentPage,
  totalPages,
  onPrevClick = () => {},
  onNextClick = () => {},
  ...props
}) => {
  return (
    <StyledCarouselControls>
      <Button
        type="button"
        onClick={onPrevClick}
        variant="link"
        reversed={reversed}
        aria-label="Previous"
        {...props}>
        <LeftArrowIcon />
      </Button>
      <ul>
        <li>{currentPage}</li>
        <li>/</li>
        <li>{totalPages}</li>
      </ul>
      <Button
        type="button"
        onClick={onNextClick}
        variant="link"
        aria-label="Next"
        reversed={reversed}
        {...props}>
        <RightArrowIcon />
      </Button>
    </StyledCarouselControls>
  );
};

export const Carousel = ({
  title = 'Explore Other Services',
  subtitle = 'Services & Products',
  children,
  reversed,
  slidesPerPage = 3,
  overrideSettings,
  ...props
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const slider = useRef(null);

  const nextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    else setCurrentPage(1);
  };

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
    else setCurrentPage(totalPages);
  };

  useEffect(() => {
    if (slider.current.children) {
      setTotalPages(Math.ceil(slider.current.children.length / slidesPerPage));
    }
  }, [children, slidesPerPage]);

  return (
    <>
      <StyledCarousel {...props}>
        {subtitle && <span>{subtitle}</span>}
        {title && <h2>{title}</h2>}
        <CarouselControls
          reversed={reversed}
          currentPage={currentPage}
          totalPages={totalPages}
          onNextClick={() => nextPage()}
          onPrevClick={() => prevPage()}
        />
        <StyledSlider
          ref={slider}
          currentPage={currentPage}
          slidesPerPage={slidesPerPage}>
          {children}
        </StyledSlider>
      </StyledCarousel>
    </>
  );
};
Carousel.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  reversed: PropTypes.bool,
  overrideSettings: PropTypes.object,
  slidesPerPage: PropTypes.number,
};
