import styled, { css } from 'styled-components';
import { colors, media } from '../../constants/theme';

export const StyledStatement = styled.div`
  position: relative;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}

  @media ${media.desktop} {
    display: flex;
    padding: ${({ condensed }) => (condensed ? '3rem 5rem' : '5rem')};
    border-radius: 24px;
  }

  ${({ styleType, bgImgSrc }) => {
    switch (styleType) {
      case 'dark':
        return css`
          ${!bgImgSrc && `background-color: rgba(9, 16, 55, 0.85);`}
          color: #fff;
        `;
      case 'cta':
        return css`
          ${!bgImgSrc && `background-color: ${colors.brandLight};`}
          color: ${colors.brand};
        `;
      default:
        return css`
          ${!bgImgSrc && `background-color: #fff;`}
          color: ${colors.text};
        `;
    }
  }};

  @media ${media.notDesktop} {
    ${({ removeMobileStyles }) =>
      removeMobileStyles
        ? `background-color: transparent !important;`
        : `
        padding: 4rem 3rem;
        border-radius: 20px;
        
    `}
  }

  /* Gutter */
  ${({ gutter }) => gutter.top && `margin-top: ${gutter.top};`}
  ${({ gutter }) => gutter.bottom && `margin-bottom: ${gutter.bottom};`}
`;

export const StyledTitle = styled.h2`
  font-size: ${({ condensed }) => (condensed ? '20px' : '30px')};
  margin-bottom: 2rem;

  @media ${media.desktop} {
    flex-shrink: 0;
    max-width: 40%;
    width: ${({ condensed }) => (condensed ? '200px' : '340px')};
    font-size: ${({ condensed }) => (condensed ? '24px' : '38px')};
    margin-right: 2rem;
  }

  > div {
    width: 130px;
  }
`;

export const StyledBody = styled.div`
  font-size: ${({ condensed }) => (condensed ? '16px' : '18px')};
`;

export const StyledBackgroundImg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  > div {
    height: 100%;
    width: 100%;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.55);
  }
`;
