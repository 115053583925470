import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { createGlobalStyle } from 'styled-components';

// Const & Utils
import { useWindowSize } from '../../utils/useWindowSize';
import { mediaSize } from '../../constants/theme';

// Components
import { LogoLight, LogoDark, LogoStackedLight, Image } from '../../components';
import {
  StyledHeader,
  StyledContainer,
  StyledLogo,
  StyledNav,
  StyledMobileToggle,
  StyledMobileNav,
  StyledBgWrapper,
} from './styles';
import { Link } from '../../components';

// Data
import data from '../../data/header.json';

const createNavSection = (data, isMobile) => {
  return (
    <ul>
      {data.map((link, index) => (
        <li key={index}>
          <Link to={link.src}>{isMobile ? link.labelMobile : link.label}</Link>
          {/* {link.children &&
        link.children.length > 0 &&
      createNavSection(link.children)} */}
        </li>
      ))}
    </ul>
  );
};

const PreventBodyOverflowStyles = createGlobalStyle`
    body {
      overflow: hidden;
    }
    `;

export const Header = ({ darkTheme, offsetMobileHeader }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [sticky, setSticky] = useState(false);
  const [allowSticky, setAllowSticky] = useState(false);

  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < 0) {
      setAllowSticky(true);
    } else {
      setAllowSticky(false);
    }
    // Set sticky if user scrolls up, unset if scroll down
    if (currPos.y > prevPos.y) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  }, [sticky, allowSticky], null, null, 300);

  const { nav } = data;

  const handleMenuOpen = () => setMenuOpen(!menuOpen);

  // Add services sub links
  // nav.services.children = Object.values(services.data).map((page) => {
  //   return {
  //     label: page.title,
  //     src: page.path,
  //   };
  // });

  const condensedNav = useWindowSize().width <= mediaSize.condensedNav;

  const isDarkTheme = darkTheme || (sticky && allowSticky);

  return (
    <StyledHeader
      darkTheme={isDarkTheme}
      sticky={sticky}
      allowSticky={allowSticky}
      offsetMobileHeader={offsetMobileHeader}>
      <StyledContainer>
        {condensedNav ? (
          <>
            {menuOpen && <PreventBodyOverflowStyles />}
            <Link to="/">
              <StyledLogo>
                {isDarkTheme ? <LogoLight /> : <LogoDark />}
              </StyledLogo>
            </Link>
            <StyledMobileToggle
              aria-label={`${menuOpen ? 'close' : 'open'} website menu`}
              menuOpen={menuOpen}
              darkTheme={isDarkTheme}
              onClick={handleMenuOpen}>
              <span></span>
              <span></span>
            </StyledMobileToggle>
            <StyledMobileNav menuOpen={menuOpen}>
              <StyledBgWrapper>
                <Image src={data.imagePath} />
              </StyledBgWrapper>
              <LogoStackedLight />
              {createNavSection(Object.values(nav), true)}
            </StyledMobileNav>
          </>
        ) : (
          <>
            <Link to="/">
              <StyledLogo>
                {isDarkTheme ? <LogoLight /> : <LogoDark />}
              </StyledLogo>
            </Link>
            <StyledNav menuOpen={menuOpen} darkTheme={isDarkTheme}>
              {createNavSection(Object.values(nav))}
            </StyledNav>
          </>
        )}
      </StyledContainer>
    </StyledHeader>
  );
};

Header.propTypes = {
  darkTheme: PropTypes.bool,
};
