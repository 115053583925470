import styled from 'styled-components';

import { media, colors } from '../../constants/theme';

export const StyledHomeContent = styled.div`
  color: ${({ reversed }) => (reversed ? '#fff' : colors.text)};
  margin: 6rem 0 8rem;

  @media ${media.notMobile} {
    display: flex;
    justify-content: space-between;
    margin: 14rem 0 14rem;
  }

  @media ${media.desktop} {
    > div {
      width: 45%;
    }
  }

  @media ${media.tablet} {
    > div {
      &:first-child {
        width: 30%;
        flex-shrink: 0;
      }
      &:last-child {
        width: 60%;
      }
    }
  }
`;

export const StyledImageList = styled.div`
  > div {
    border-radius: 25px;
    overflow: hidden;

    @media ${media.notMobile} {
      margin-bottom: -1.5rem;
      width: 70%;
      box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.25);

      &:nth-child(2) {
        margin-left: 20%;
      }
    }

    @media ${media.mobile} {
      margin-bottom: 3rem;

      &:not(:nth-child(2)) {
        display: none;
      }
    }
  }
`;

export const StyledBlocks = styled.div`
  > div {
    margin-bottom: 4rem;

    h3 {
      margin-bottom: 2rem;
    }

    &:first-child {
      @media ${media.notMobile} {
        h3 {
          font-size: 44px;
        }
        p {
          font-size: 18px;
        }
      }

      @media ${media.mobile} {
        h3 {
          font-size: 28px;
        }
        p {
          font-size: 18px;
        }
      }
    }

    &:not(:first-child) {
      @media ${media.notMobile} {
        h3 {
          font-size: 24px;
        }
        p {
          font-size: 18px;
        }
      }

      @media ${media.mobile} {
        h3 {
          font-size: 18px;
        }
      }
    }
  }
`;
