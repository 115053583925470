import styled from 'styled-components';
import { Container } from '../container';
import { colors, media } from '../../constants/theme';

export const StyledFooter = styled.footer`
  background-color: ${colors.darkBg};

  &, a:not(:hover) {
    color: #fff;
  }
`;

export const StyledFooterBody = styled(Container)`
  padding-top: 3rem;
  padding-bottom: 3rem;

  @media ${media.desktop} {
    display: flex;
  }
`;

export const StyledFooterCol = styled.div`
  @media ${media.notMobile} {
    flex-shrink: 0;
    display: flex;
    align-items: center;
  }
  @media ${media.tablet} {
    justify-content: center;
  }
  @media ${media.desktop} {
    width: 50%;
  }

  p {
    color: ${colors.darkMute};

    @media ${media.mobile} {
      text-align: center;
      font-size: 12px;
      margin: 2rem 0;
    }

    @media ${media.notMobile} {
      margin-left: 3rem;
      max-width: 340px;
      font-size: 14px;
    }
  }

  ul {
    @media ${media.mobile} {
      text-align: center;
      line-height: 2.5;
      font-size: 14px;
    }

    @media ${media.notMobile} {
      width: 50%;
      padding: 0 3rem;
      line-height: 2;
    }

    @media ${media.tablet} {
      padding: 2rem 1rem 0;
      &:first-child {
        text-align: right;
      }
    }
  }
`;

export const StyledLogo = styled.div`
  width: 168px;
  height: auto;
  flex-shrink: 0;

  @media ${media.mobile} {
    width: auto;
    height: 130px;
    margin: 0 auto;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const StyledCopyright = styled.div`
  border-top: 1px solid #333;
  font-size: 12px;
  padding: 2rem 0;
  text-align: center;
  color: ${colors.darkMute};

  @media ${media.mobile} {
    font-size: 9px;
  }
`;
