import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledButton,
  StyledLink,
  StyledArrowIcon,
  StyledAnchor,
} from './styles';

const ArrowIcon = (
  <StyledArrowIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="m173 32l-45 42 173 179-173 185 42 42 214-227z" />
  </StyledArrowIcon>
);

export const Button = ({
  variant = 'solid',
  reversed = false,
  showArrow = false,
  gutter = {},
  children,
  type,
  href,
  disabled,
  ...props
}) => {
  const ButtonComponent = type
    ? StyledButton
    : href
    ? StyledAnchor
    : StyledLink;
  return (
    <ButtonComponent
      type={type}
      href={href}
      $variant={variant}
      $reversed={reversed}
      $gutter={gutter}
      disabled={disabled}
      {...props}>
      {children}
      {showArrow && ArrowIcon}
    </ButtonComponent>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  variant: PropTypes.oneOf(['solid', 'outline', 'link']),
  reversed: PropTypes.bool,
  showArrow: PropTypes.bool,
  gutter: PropTypes.object,
};
