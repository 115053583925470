import styled, { css } from 'styled-components';

import { Link } from '../link';
import { colors, font } from '../../constants/theme';

const BaseStyles = css`
  /* Base styles */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 16px;
  height: 46px;
  transition: all 200ms ease-in;
  font-weight: ${font.weight.button};

  /* Variations */
  ${({ $reversed, $variant }) =>
    $variant !== 'link'
      ? `
		padding: 0 2rem;
		border-width: 2px;
		border-style: solid;
		border-color: ${$reversed ? '#fff' : colors.brand};
    
    &, &:hover {
      color: ${
        $variant === 'outline'
          ? $reversed
            ? '#fff'
            : colors.brand
          : $reversed
          ? colors.brand
          : '#fff'
      };
    }
		background-color: ${
      $variant === 'outline' ? 'transparent' : $reversed ? '#fff' : colors.brand
    };
	`
      : `
		padding: 0 6px;
		color: ${$reversed ? '#fff' : colors.brand};
	`}

  &:hover {
    ${({ $reversed, $variant }) =>
      $variant === 'solid'
        ? `
			background-color: ${$reversed ? '#dbf0f3' : colors.brandDark};
			border-color: ${$reversed ? '#dbf0f3' : colors.brandDark};
		`
        : `
			background-color: rgba(3, 169, 244, 0.2);
		`}
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #607d8b;
  }

  &:active {
    transform: scale(0.95);
  }

  ${({ disabled }) =>
    disabled
      ? `
      &, &:disabled {
        background-color: #909090;
        border-color: #909090;
        pointer-events: none;
      `
      : undefined}

  /* Gutter */
  ${({ $gutter }) => $gutter.top && `margin-top: ${$gutter.top};`}
  ${({ $gutter }) => $gutter.bottom && `margin-bottom: ${$gutter.bottom};`}
  ${({ $gutter }) => $gutter.left && `margin-left: ${$gutter.left};`}
  ${({ $gutter }) => $gutter.right && `margin-right: ${$gutter.right};`}
`;

export const StyledButton = styled.button`
  /* Resets */
  border: none;
  margin: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  cursor: pointer;
  font: inherit;
  line-height: normal;
  user-select: none;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  /* Styles */
  ${BaseStyles}
`;

export const StyledLink = styled(Link)`
  ${BaseStyles}
`;

export const StyledAnchor = styled.a`
  ${BaseStyles}
`;

export const StyledArrowIcon = styled.svg`
  margin-left: 4px;
  width: 14px;
  height: 14px;
  display: block;
  fill: currentColor;
`;
