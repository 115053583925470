import React from 'react';
import PropTypes from 'prop-types';

import { LogoIconDark } from '../logos';
import { StyledLogo } from './styles';

import GoogleMapReact from 'google-map-react';

export const Map = ({ apiKey, center, zoom = 15 }) => {
  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: apiKey }}
      defaultCenter={center}
      defaultZoom={zoom}>
      <StyledLogo lat={center.lat} lng={center.lng}>
        <LogoIconDark />
      </StyledLogo>
    </GoogleMapReact>
  );
};

Map.propTypes = {
  apiKey: PropTypes.string.isRequired,
  center: PropTypes.object.isRequired,
  zoom: PropTypes.number,
};
