import styled from 'styled-components';

import { media } from '../../constants/theme';

export const StyledPartners = styled.div`
  margin-bottom: 6rem;

  @media ${media.notMobile} {
    margin-top: -2rem;
    display: flex;
  }

  /* Title */
  > div:first-child {
    @media ${media.notMobile} {
      width: 35%;
      flex-shrink: 0;
    }

    @media ${media.notMobile} {
      h2 {
        font-size: 44px;
        margin-bottom: 2rem;
      }

      p {
        font-size: 18px;
      }
    }
    @media ${media.mobile} {
      h2 {
        font-size: 28px;
        margin-bottom: 2rem;
      }

      p {
        font-size: 16px;
        margin-bottom: 2rem;
      }
    }
  }

  /* Logo list */
  > div:last-child {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    @media ${media.notMobile} {
      margin-left: auto;
      width: 60%;
      flex-shrink: 0;
    }

    a {
      @media ${media.desktop} {
        width: 28%;
        transition: all 200ms ease-in-out;

        &:hover {
          transform: scale(1.1);
        }
      }
      @media ${media.notDesktop} {
        width: 40%;
      }
    }
  }
`;
