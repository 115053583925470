export const colors = {
  text: '#333333',
  link: '#091037',
  mute: '#757575',
  darkMute: '#9e9e9e',

  brand: '#0e1431',
  brandDark: '#020517',
  brandLight: '#E6ECED',
  brandAlt: '#004F5F', // testimonials
  highlight: '#256a77', // teal

  darkBg: '#161616',
};

export const font = {
  family: {
    default:
      'Lato, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji',
    alt:
      'Montserrat, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji',
  },
  weight: {
    bold: 700,
    normal: 400,
    // light: 300,
    button: 700,
    navLinks: 700,
  },
};

export const header = {
  fullHeight: '120px',
  negativeFullHeight: '-120px',
  condensedHeight: '80px',
  negativeCondensedHeight: '-80px',
}

export const screenMin = {
  xxs: 375,
  xs: 480,
  sm: 768,
  md: 996,
  lg: 1280,
  xlg: 1440,
  xxlg: 1580,
};

export const screenMax = {
  xxs: screenMin.xs - 1,
  xs: screenMin.sm - 1,
  sm: screenMin.md - 1,
  md: screenMin.lg - 1,
  lg: screenMin.xlg - 1,
  xlg: screenMin.xxlg - 1,
};

export const minWidth = {
  xs: `(min-width: ${screenMin.xs}px)`,
  sm: `(min-width: ${screenMin.sm}px)`,
  md: `(min-width: ${screenMin.md}px)`,
  lg: `(min-width: ${screenMin.lg}px)`,
  xlg: `(min-width: ${screenMin.xlg}px)`,
  xxlg: `(min-width: ${screenMin.xxlg}px)`,
};

export const maxWidth = {
  xxs: `(max-width: ${screenMax.xxs}px)`,
  xs: `(max-width: ${screenMax.xs}px)`,
  sm: `(max-width: ${screenMax.sm}px)`,
  md: `(max-width: ${screenMax.md}px)`,
  lg: `(max-width: ${screenMax.lg}px)`,
  xlg: `(max-width: ${screenMax.xlg}px)`,
};

export const breakPoint = {
  xxs: maxWidth.xxs,
  xs: `${minWidth.xs} and ${maxWidth.xs}`,
  sm: `${minWidth.sm} and ${maxWidth.sm}`,
  md: `${minWidth.md} and ${maxWidth.md}`,
  lg: `${minWidth.lg} and ${maxWidth.lg}`,
  xlg: `${minWidth.xlg} and ${maxWidth.xlg}`,
  xxlg: minWidth.xxlg,
};

// Specifically for useWindowSize
export const mediaSize = {
  mobile: screenMax.xs,
  notMobile: screenMin.sm,
  desktop: screenMin.md,
  notDesktop: screenMax.sm,
  condensedNav: screenMax.sm,
  fullNav: screenMin.md,
};

export const media = {
  mobile: `(max-width: ${mediaSize.mobile}px)`,
  notMobile: `(min-width: ${mediaSize.notMobile}px)`,
  tablet: breakPoint.sm,
  desktop: `(min-width: ${mediaSize.desktop}px)`,
  notDesktop: `(max-width: ${mediaSize.notDesktop}px)`,
  condensedNav: `(max-width: ${mediaSize.condensedNav}px)`,
  fullNav: `(min-width: ${mediaSize.fullNav}px)`,
};
