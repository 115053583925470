import { createGlobalStyle } from 'styled-components';
import { colors, font } from '../../constants/theme';

export const GlobalStyles = createGlobalStyle`
  *, :after, :before {
    box-sizing: border-box;
  }
  html, body, div, span, applet, object, iframe, table, caption, tbody, tfoot, thead, tr, th, td, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, dl, dt, dd, ol, ul, li, fieldset, form, label, legend {
    vertical-align: baseline;
    font-family: inherit;
    font-weight: inherit;
    font-style: inherit;
    font-size: 100%;
    outline: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }
  html, body {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
  }
  body {
    font-size: 16px;
    font-family: ${font.family.default};
    font-weight: ${font.weight.normal};

    ${({ isDarkTheme }) =>
      isDarkTheme
        ? `
        background-color: ${colors.brand};
        color: #fff;
      `
        : `
        color: ${colors.text};
        background: #fff;
      `}
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: ${font.family.alt};
    font-weight: ${font.weight.bold};
    line-height: 1.3;
  }
  p {
    line-height: 2;
  }
  a {
    text-decoration: none;
    
    &:hover {
        color: ${colors.highlight};
      }
  }
  ol, ul {
    list-style: none;
    margin: 0;
  }
  table {
    border-collapse: separate;
    border-spacing: 0;
    }
  caption, th, td {
    font-weight: normal;
    text-align: left;
    }
  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }
  button,
  select { /* 1 */
    text-transform: none;
  }
  button,
  input { /* 1 */
    overflow: visible;
  }
`;
